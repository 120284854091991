export default {
    props: {
        values: {
            type: Array,
            required: false,
            default: () => {
                return [];
            },
        },
    },
    data: () => {
        return {
            websiteId: null,
        };
    },
    methods: {
        getValue(key) {
            let obj = this.values.find(item => item.field_name === key);

            return obj ? obj.field_value : null;
        },
        createWebsiteImage(file) {
            let params = {
                websiteId: this.websiteId,
                data: {
                    image: {
                        id_website_images: file.file_uuid,
                        website_images_url: file.thumbnail_url,
                        website_images_name: file.original_name,
                    },
                },
            };
            this.$cmsDataProvider.create('createWebsiteImages', params);
        },
    },
    created() {
        this.websiteId = this.$route.params.websiteId;
    },
};
