<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">image galleries: side-by-side</div>
        <div class="module-preview--contents">            
            <div class="gallery pb-4 relative">
                <div class="text-lg font-600 mb-1">{{ getValue('galleryOneTitle') }}</div>
                <div class="image-wrapper" v-if="galleryOneImageIds">
                    <img :src="(galleryOneImageIds[0])" alt="">
                </div>
                <span class="count bg-blue-100 border border-blue-300 py-3 px-5 text-blue-900 font-500 text-sm" v-if="galleryOneImageIds">+{{ galleryOneImageIds.length - 1 }} images</span>
            </div>
            <div class="gallery pb-4 relative">
                <div class="text-lg font-600 mb-1">{{ getValue('galleryTwoTitle') }}</div>
                <div class="image-wrapper" v-if="galleryTwoImageIds">
                    <img :src="(galleryTwoImageIds[0])" alt="">
                </div>
                <span class="count bg-blue-100 border border-blue-300 py-3 px-5 text-blue-900 font-500 text-sm" v-if="galleryTwoImageIds">+{{ galleryTwoImageIds.length - 1 }} images</span>
            </div>
        </div>
    </div>    
</template>

<script>
    import {mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";

    export default {
        mixins: [ Widget ],
        data: () => {
            return {
                galleryOneImageIds: [],
                galleryTwoImageIds: [],
            }
        },
        computed: {
            ...mapGetters({
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId'
            })
        },
        created() {
            this.galleryOneImageIds = JSON.parse(this.getValue('galleryOneImages'));
            this.galleryTwoImageIds = JSON.parse(this.getValue('galleryTwoImages'));
        }
    }
</script>
<style scoped>
    .module-preview--contents{
        grid-template-columns: 1fr 1fr;        
    }
    .module-preview--contents .gallery{
        
    }
    .module-preview--contents .image-wrapper{        
        height: 225px;
        width: 100%;
        overflow: hidden;
    } 
    .module-preview--contents .image-wrapper img{
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
    }
    .count {
        position: absolute;
        right: 10px;
        bottom: 0px;
    }
</style>